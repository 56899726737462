import { checkForPasskeysAndLogin } from "../passkey_login"

document.addEventListener("DOMContentLoaded", () => {
  let form = document.getElementById("sign-in-form")

  checkForPasskeysAndLogin(form)

  let emailField = document.getElementById("email")
  let signInButton = document.getElementById("sign-in-button")
  emailField.addEventListener("focusout", () => emailField.value = emailField.value.trim())

  let logo = document.querySelector("#nitro-id-logo")
  logo.addEventListener("dblclick", () => {
    document.cookie = "_nitro_id_passkeys_enabled=true"
    window.location.reload()
  })

  form.onsubmit = () => {
    signInButton.disabled = true
    signInButton.innerText = "Signing In..."
  }
})
