import {
  get,
  parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill"
import { FetchRequest } from '@rails/request.js'

const checkForPasskeysAndLogin = async (form) => {
  const challengeFetch = new FetchRequest('post', "/passkeys/login", {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })

  const browserSupportsPasskeys = () => {
    const publicKeyCredential = window.PublicKeyCredential
    return publicKeyCredential
      && publicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      && publicKeyCredential.isConditionalMediationAvailable()
  }

  const hasPasskeyCookie = () => document.cookie.includes("_nitro_id_passkeys")

  if (browserSupportsPasskeys() && hasPasskeyCookie()) {
    const challengeResponse = await (await challengeFetch).perform()
    const json = await challengeResponse.json
    const credentialAuthenticationOptions = parseRequestOptionsFromJSON({ publicKey: json })
    const credentialAuthenticationResponse = await get(credentialAuthenticationOptions)

    form.elements["publicKeyCredential"].value = JSON.stringify(credentialAuthenticationResponse)
    form.submit()
  }
}

export { checkForPasskeysAndLogin }
